import { render, staticRenderFns } from "./SubscriberPage.vue?vue&type=template&id=0bc0f1d8&scoped=true&"
import script from "./SubscriberPage.vue?vue&type=script&lang=ts&"
export * from "./SubscriberPage.vue?vue&type=script&lang=ts&"
import style0 from "./SubscriberPage.vue?vue&type=style&index=0&id=0bc0f1d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bc0f1d8",
  null
  
)

export default component.exports