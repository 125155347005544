



























































































































































































































































































































import BaseButton from '@/components/base/BaseButton.vue';
import { defineComponent, ref } from '@/plugins/composition';
import SubscriberText from './SubscriberText.vue';
import { rules } from '@/composables/useValidation/validations';
// import { debouncedWatch } from '@vueuse/core';
// import { User } from '@/models';
import { routeNames } from '@/router/routeNames';
import { useLoader } from '@/composables/useLoader';
import { useRegistrationData } from '@/composables/useRegistrationData';
import { mdiEyeOutline } from '@mdi/js';
import { useApi } from '@/composables/useApi';
import { snakeKeys } from '@/utils/case';
import { BookedUser } from '@/models/booked/BookedUser';
import { useAuthStore } from '@/composables/useAuthStore';
import { useStatus } from '@/composables/useStatus';
import { useRouter } from '@/router/useRouter';
import { User } from '@/models';

export default defineComponent({
  components: { SubscriberText, BaseButton },
  name: 'SubscriberForm',
  setup() {
    const valid = ref(true);
    const isPasswordVisible = ref(false);
    const { loading, withLoader } = useLoader();
    const {
      artforms,
      step,
      accountType,
      formData,
      reset,
      type,
      updateProgress,
      progress,
      typeSelected,
      isForOrganisation,
      orgTypes,
      goToStep,
      emailExists,
      next,
      organisation,
    } = useRegistrationData();
    const { setMessage } = useStatus();
    const { user, refreshState, isAuthed,storeLogin, getLocalAuth } = useAuthStore();
    const formDataSubmit = ref<BookedUser>({} as BookedUser);
    const { post, put, get } = useApi();
    
    if (isAuthed) {
      if (formData.firstName == '') {
        formData.firstName = user?.value?.data?.firstName;
      }
      if (formData.lastName == '') {
        formData.lastName = user?.value?.data?.lastName;
      }
      if (formData.email == '') {
        formData.email = user?.value?.data?.email;
      }
    }
    const { router } = useRouter();
    const register = async () => {
      withLoader(async () => {
        await post('/users', snakeKeys(formData));
      });
    };

    // watchEffect(() => {
    //   console.log(valid.value, 'valid watchEffect');
    //   console.log(step.value, 'step');
    // });

    const updateUser = async (type: string) => {
      return withLoader(async () => {
        let uuid = user?.value?.data?.uuid ?? '';
        const response = await put(`/users/${uuid}`, snakeKeys(formData));
        let authData = getLocalAuth();

        if (authData !== undefined) {
          let user: User = authData?.user as User;
          user.data.customAttributes = response?.customAttributes as never;
          await storeLogin(user);
        }
        if (response === undefined) {
          return false;
        }
        if (response !== undefined && type == 'General_Public') {
          // reset();
          // router.push({ name: routeNames.spaces.book });
          goToStep(2);
        }
        return response;
      });
    };
    const checkTypeOfOrganization = async () => {
      if (formData.accountType === 'Individual') {
        goToStep(4);
        updateProgress(20);
      } else if (formData.accountType === 'Arts_Organisation') {
        goToStep(3);
      } else if (formData.accountType === 'General_Public') {
        await updateUser('General_Public');
      }
    };
    const updateArtistFlow = async (step: number) => {
      if(step == 7) {
        formData.final_step = true;
      }
      const res = await updateUser(formData.accountType);
      if (res !== undefined) {
        goToStep(step);
        updateProgress(20);
      }
    };
    const goAheadToBilling = async () => {
      reset();
      router.push({ name: routeNames.profile.subscribe });
    };
    const noThankYou = async () => {
      reset();
      let uuid = user?.value?.data?.uuid ?? '';
      const response = await get(`/users/${uuid}/no-membership`);
      //router.push({ name: routeNames.home });
      //router.push({ name: routeNames.subscriber });
      goToStep(2);
    };
    const checkRadio = async (value: string) => {
      formData.accountType = value;
    };
    const checkRadioArtType = async (value: string) => {
      formData.artform = value;
    };
    const checkRadioOrgType = async (value: string) => {
      formData.organisationType = value;
    };
    const getType = (value: string) => {
      let data = accountType.filter((item) => {
        return item.key === formData.accountType; // Assuming item.key is what you want to match
      });
      if (data.length > 0) {
        if (value == 'rate') {
          return data[0].rate;
        }
        if (value == 'saving') {
          let saving = (100 * data[0].rate) / 66;

          return Math.round(100 - saving);
        }
      }
      return '';
    };
    const helpUsPage = async () => {
      const res = await updateUser(formData.accountType);
      if (res !== undefined) {
        updateProgress(20);
        goToStep(4);
      }
    };
    
    return {
      type,
      isForOrganisation,
      typeSelected,
      formData,
      formDataSubmit,
      rules,
      valid,
      accountType,
      artforms,
      getType,
      loading,
      emailExists,
      register,
      updateUser,
      helpUsPage,
      goAheadToBilling,
      noThankYou,
      organisation,
      checkTypeOfOrganization,
      checkRadioArtType,
      checkRadioOrgType,
      updateArtistFlow,
      step,
      next,
      checkRadio,
      goToStep,
      updateProgress,
      progress,
      routeNames,
      isPasswordVisible,
      icons: {
        mdiEyeOutline,
      },
      orgTypes,
    };
  },
});
