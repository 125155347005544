















import { defineComponent } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';

export default defineComponent({
  name: 'RegisterText',
  setup() {
    return {
      routeNames,
    };
  },
});
