






















import { defineComponent } from '@/plugins/composition';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router/useRouter';
import { useAuthStore } from '@/composables/useAuthStore';

export default defineComponent({
  name: 'SubscriptionPage',
  props: {
    title: {
      type: String,
      default: '',
    },
    heading: {
      type: String,
      default: null,
      required: false,
    },
    titleClass: {
      type: String,
      default: '',
      required: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  setup() {
    const { md } = useBreakpoints(breakpointsTailwind);
    const { route } = useRouter();
    const { isAuthed, isMember } = useAuthStore();
    const { router } = useRouter();
    const goHome = () => {
      if (!isAuthed.value) {
        return router.push('/');
      }

      if (!isMember.value) {
        return router.push('/');
      }

      router.push({ name: routeNames.dashboard });
    };
    return {
      goHome,
      md,
      routeNames,
      route,
    };
  },
});
